import axios from "axios";

const api = axios.create({
  // baseURL: "http://localhost:5000/",
  baseURL:"https://nya-backend-pfvo.onrender.com/",

});

api.interceptors.request.use(
  (req) => {
    if (localStorage.getItem("profile")) {
      req.headers.Authorization = `Bearer ${
        JSON.parse(localStorage.getItem("profile")).authToken
      }`;
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const addAdmin = (userInfo) => api.post(`api/admin/register`, userInfo);
export const loginAdmin = (userInfo) => api.post(`api/admin/login`, userInfo);

export const sendOtp = (otpInfo) => api.post(`api/admin/sendOTP`, otpInfo);
export const verifyOtp = (otpInfo) => api.post(`api/admin/verifyOTP`, otpInfo);
export const generatePassword = (userInfo) => api.post(`api/admin/generatePassword`, userInfo);
export const addCategory = (formData) => {
  return api.post(`api/admin/createCategory`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getCategory = (params) => api.get(`api/admin/getCategory`,{params});
export const deleteCategory = (id) =>
  api.delete(`api/admin/deleteCategory/${id}`); //not check

  export const deleteSubCategory = (id) =>
  api.delete(`api/admin/deleteSubCategory/${id}`); //not check

export const addCelebrity = (userInfo) => {
  return api.post(`api/admin/createCelebrity`,userInfo);
};
export const getCelebrity = (params) => api.get(`api/admin/celebrities`,{params});
export const updateCelebrity = ({id,...userInfo}) => {
  return api.put(`api/admin/updateCelebrity/${id}`,userInfo);
};

export const deleteCelebrity = (id) =>
  api.delete(`api/admin/deleteCelebrity/${id}`);

export const addSubCategory = (formData) => {
  return api.post(`api/admin/createSubCategory`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getSubCategory = (params) => api.get(`api/admin/getSubCategory`,{params});

export const addAasana = (aasanaInfo) => {
  return api.post(`api/admin/createAasana`, aasanaInfo);
};
export const getAasana = (params) => api.get(`api/admin/getAasana`,{params});

export const publishCategory = (id) => {
  return api.put(`api/admin/publishCategory/${id}`);
};
export const updateCategory = (formData) => {
  const id = formData.get('id');
  formData.delete('id');
  return api.put(`api/admin/updateCategory/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const unPublishCategory = (id) => {
  return api.put(`api/admin/unPublishCategory/${id}`);
};

export const publishSubCategory = (id) => {
  return api.put(`api/admin/publishSubCategory/${id}`);
};
export const updateSubCategory = (formData) => {
  const id = formData.get('id');
  formData.delete('id');
  return api.put(`api/admin/updateSubCategory/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const unPublishSubCategory = (id) => {
  return api.put(`api/admin/unPublishSubCategory/${id}`);
};

export const publishAasana = (id) => {
  return api.put(`api/admin/publishAasana/${id}`);
};
export const updateAasana = ({ id, ...aasanaInfo }) => {
  return api.put(`api/admin/updateAasana/${id}`, aasanaInfo);
};

export const unPublishAasana = (id) => {
  return api.put(`api/admin/unPublishAasana/${id}`);
};

export const deleteAasana = (id) =>
  api.delete(`api/admin/hardDeleteAasana/${id}`);
export const getUser = (params) => api.get(`api/admin/users`,{params});


export const addInstructor = (instructor) =>
  api.post(`api/instructor/register`, instructor);
export const loginInstructor = (instructor) =>
  api.post(`api/instructor/login`, instructor);
  export const instructorPassword = (instructor) =>
  api.post(`api/instructor/changePassword`, instructor);
export const getAdminInstructor = (params) => api.get(`api/admin/instructors`,{params});

export const approveInstructor = (id) => {
  return api.put(`api/admin/approveInstructorRegistration/${id}`);
};
export const declineInstructor = (id) => {
  return api.put(`api/admin/disApproveInstructorRegistration/${id}`);
};

export const getUpdationInstructor = (params) => api.get(`api/admin/instructorsUpdation`,{params});
export const approveInstructorUpdation = (id) => {
  return api.put(`api/admin/approveInstructorUpdation/${id}`);
};
export const declineInstructorUpdation = (id) => {
  return api.put(`api/admin/disApproveInstructorUpdation/${id}`);
};

export const getInstructor = () => api.get(`api/instructor/instructor`);
export const updateInstructor = (instructorInfo) => {
  return api.put(`api/instructor/updateInstructor`,instructorInfo);
};

export const addInstitute = (institute) =>
  api.post(`api/institute/register`, institute);
  export const loginInstitute = (institute) =>
  api.post(`api/institute/login`, institute);
  export const getInstitute = () => api.get(`api/institute/institute`);
export const updateInstitute = (instituteInfo) => {
  return api.put(`api/institute/updateInstitute`,instituteInfo);
};

  export const getAdminInstitute = (params) => api.get(`api/admin/institutes`,{params});

  export const approveInstitute = (id) => {
    return api.put(`api/admin/approveInstituteRegistration/${id}`);
  };
  export const declineInstitute = (id) => {
    return api.put(`api/admin/disApproveInstituteRegistration/${id}`);
  };

  export const getUpdationInstitute = (params) => api.get(`api/admin/institutesUpdation`,{params});
export const approveInstituteUpdation = (id) => {
  return api.put(`api/admin/approveInstituteUpdate/${id}`);
};
export const declineInstituteUpdation = (id) => {
  return api.put(`api/admin/disApproveInstituteUpdate/${id}`);
};

export const institutePassword = (institute) =>
api.post(`api/institute/changePassword`, institute);

export const addInstructorEvent = (formData) => {
  return api.post(`api/instructor/createEvent`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getInstructorEvent = () => api.get(`api/instructor/events`);

export const addInstituteEvent = (formData) => {
  return api.post(`api/institute/createEvent`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getInstituteEvent = () => api.get(`api/institute/events`);
export const getAdminEvent = (params) => api.get(`api/admin/events`,{params});

export const approveEvent = (id) => {
  return api.put(`api/admin/approveEventCreation/${id}`);
};
export const declineEvent = (id) => {
  return api.put(`api/admin/disApproveEventCreation/${id}`);
};

export const getInstituteEventById = (id) => api.get(`api/institute/events/${id}`);

export const updateInstituteEvent = async (formData) => {
  const id = formData.get("id");
  const formDataWithoutId = new FormData();
  formData.forEach((value, key) => {
    if (key !== "id") {
      formDataWithoutId.append(key, value);
    }
  });
  return api.put(`api/institute/updateEvent/${id}`, formDataWithoutId, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
};

export const getInstructorEventById = (id) => api.get(`api/instructor/events/${id}`);

export const updateInstructorEvent = async (formData) => {
  const id = formData.get("id");
  const formDataWithoutId = new FormData();
  formData.forEach((value, key) => {
    if (key !== "id") {
      formDataWithoutId.append(key, value);
    }
  });
  return api.put(`api/instructor/updateEvent/${id}`, formDataWithoutId, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
};

export const getUpdationEvent = (params) => api.get(`api/admin/getEventUpdation`,{params});

export const approveEventUpdation = (id) => {
  return api.put(`api/admin/approveEventUpdation/${id}`);
};
export const declineEventUpdation = (id) => {
  return api.put(`api/admin/disApproveEventUpdation/${id}`);
};

export const addInstructorQuiz = (formData) => {
  return api.post(`api/instructor/createQuiz`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getAdminQuiz = () => api.get(`api/admin/quizs`);
export const approveQuiz = (id) => {
  return api.put(`api/admin/approveQuizCreation/${id}`);
};
export const declineQuiz = (id) => {
  return api.put(`api/admin/disApproveQuizCreation/${id}`);
};

export const getEventBookByUser = (id) => api.get(`api/admin/eventUsers/${id}`);