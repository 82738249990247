export const ADD_ADMIN = 'ADD_ADMIN';
export const LOGIN_ADMIN = 'LOGIN_ADMIN';
export const LOGOUT_ADMIN='LOGOUT_ADMIN';

export const ADD_CATEGORY = 'ADD_CATEGORY';
export const GET_CATEGORY = 'GET_CATEGORY';
export const PUBLISH_CATEGORY="PUBLISH_CATEGORY";
export const UNPUBLISH_CATEGORY="UNPUBLISH_CATEGORY"
export const UPDATE_CATEGORY='UPDATE_CATEGORY';
export const DELETE_CATEGORY='DELETE_CATEGORY';

export const ADD_SUB_CATEGORY = 'ADD_SUB_CATEGORY';
export const GET_SUB_CATEGORY = 'GET_SUB_CATEGORY';
export const PUBLISH_SUB_CATEGORY="PUBLISH_SUB_CATEGORY";
export const UNPUBLISH_SUB_CATEGORY="UNPUBLISH_SUB_CATEGORY";
export const UPDATE_SUB_CATEGORY='UPDATE_SUB_CATEGORY';
export const DELETE_SUB_CATEGORY='DELETE_SUB_CATEGORY';

export const ADD_AASANA = 'ADD_AASANA';
export const GET_AASANA = 'GET_AASANA';
export const PUBLISH_AASANA="PUBLISH_AASANA";
export const UNPUBLISH_AASANA="UNPUBLISH_AASANA"
export const UPDATE_AASANA='UPDATE_AASANA';
export const DELETE_AASANA='DELETE_AASANA';

export const GET_USERS='GET_USERS';

export const ADD_INSTRUCTOR='ADD_INSTRUCTOR';
export const LOGIN_INSTRUCTOR='LOGIN_INSTRUCTOR';
export const GET_INSTRUCTOR='GET_INSTRUCTOR';
export const UPDATE_INSTRUCTOR='UPDATE_INSTRUCTOR';
export const INSTRUCTOR_PASSWORD='INSTRUCTOR_PASSWORD';
export const ADD_INSTRUCTOR_EVENT='ADD_INSTRUCTOR_EVENT';
export const GET_INSTRUCTOR_EVENT='GET_INSTRUCTOR_EVENT';
export const GET_INSTRUCTOR_EVENT_BY_ID='GET_INSTRUCTOR_EVENT_BY_ID';
export const UPDATE_INSTRUCTOR_EVENT='UPDATE_INSTRUCTOR_EVENT';

export const GET_ADMIN_INSTRUCTOR='GET_ADMIN_INSTRUCTOR';
export const APPROVE_INSTRUCTOR='APPROVE_INSTRUCTOR';
export const DECLINE_INSTRUCTOR='DECLINE_INSTRUCTOR';
export const GET_UPDATION_INSTRUCTOR='GET_UPDATION_INSTRUCTOR';
export const APPROVE_INSTRUCTOR_UPDATION='APPROVE_INSTRUCTOR_UPDATION';
export const DECLINE_INSTRUCTOR_UPDATION='DECLINE_INSTRUCTOR_UPDATION';
export const GET_ADMIN_EVENT='GET_ADMIN_EVENT';
export const APPROVE_EVENT='APPROVE_EVENT';
export const DECLINE_EVENT='DECLINE_EVENT';
export const APPROVE_EVENT_UPDATION='APPROVE_EVENT_UPDATION';
export const DECLINE_EVENT_UPDATION='DECLINE_EVENT_UPDATION';
export const GET_UPDATION_EVENT='GET_UPDATION_EVENT';
export const GET_ADMIN_QUIZ='GET_ADMIN_QUIZ';
export const APPROVE_QUIZ='APPROVE_QUIZ';
export const DECLINE_QUIZ='DECLINE_QUIZ';
export const GET_EVENT_BOOK_BY_USER='GET_EVENT_BOOK_BY_USER';

export const ADD_INSTITUTE='ADD_INSTITUTE';
export const LOGIN_INSTITUTE='LOGIN_INSTITUTE';
export const GET_INSTITUTE='GET_INSTITUTE';
export const UPDATE_INSTITUTE='UPDATE_INSTITUTE';
export const INSTITUTE_PASSWORD='INSTITUTE_PASSWORD';
export const ADD_INSTITUTE_EVENT='ADD_INSTITUTE_EVENT';
export const GET_INSTITUTE_EVENT='GET_INSTITUTE_EVENT';
export const GET_INSTITUTE_EVENT_BY_ID='GET_INSTITUTE_EVENT_BY_ID';
export const UPDATE_INSTITUTE_EVENT='UPDATE_INSTITUTE_EVENT';

export const GET_ADMIN_INSTITUTE='GET_ADMIN_INSTITUTE';
export const APPROVE_INSTITUTE='APPROVE_INSTITUTE';
export const DECLINE_INSTITUTE='DECLINE_INSTITUTE';
export const GET_UPDATION_INSTITUTE='GET_UPDATION_INSTITUTE';
export const APPROVE_INSTITUTE_UPDATION='APPROVE_INSTITUTE_UPDATION';
export const DECLINE_INSTITUTE_UPDATION='DECLINE_INSTITUTE_UPDATION';

export const ADD_INSTRUCTOR_QUIZ='ADD_INSTRUCTOR_QUIZ';

export const ADD_CELEBRITY='ADD_CELEBRITY';
export const GET_CELEBRITY='GET_CELEBRITY';
export const UPDATE_CELEBRITY='UPDATE_CELEBRITY';
export const DELETE_CELEBRITY='DELETE_CELEBRITY';

export const SEND_OTP='SEND_OTP';
export const VERIFY_OTP='VERIFY_OTP';
export const GENERATE_PASSWORD='GENERATE_PASSWORD';
